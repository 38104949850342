/** @jsx jsx */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { jsx } from 'theme-ui';
import { Text, Flex, Link, Card, Box } from 'rebass';
import { Image } from '../../ui-components/images/images';

import MainContainer from '../../ui-components/container/container';

interface Props {
  data: {
    title?: boolean;
    articles: [
      {
        metaTitle: string;
        metaDescription: {
          metaDescription: string;
        };
        socialMediaImage: {
          file: {
            url: string;
          };
        };
        slug: string;
      }
    ];
  };
}

export const query = graphql`
  fragment FeaturedArticles on ContentfulFeaturedArticles {
    title
    articles {
      ... on ContentfulBlogArticle {
        metaTitle
        metaDescription {
          metaDescription
        }
        socialMediaImage {
          ...ContentfulAsset
        }
        slug
      }
    }
    internal {
      type
    }
  }
`;

const Cards = ({ data }: Props) => {
  const { title, articles } = data;
  return (
    <MainContainer my={4} key={title}>
      {title && (
        <Text as="h2" variant="subheading">
          {title}
        </Text>
      )}
      <Flex justifyContent="space-between" flexWrap="wrap">
        {articles.map(
          ({ slug, metaTitle, metaDescription, socialMediaImage }): any => {
            return (
              <Box key={metaTitle} variant="cardBlogContainer">
                <Link href={slug} variant="cardBlogLink">
                  <Card variant="cardImage">
                    <Image
                      src={socialMediaImage}
                      className="image--blog-post"
                    />
                    <Flex flexWrap="wrap" p={[2]}>
                      <Text as="h3" variant="cardTitle">
                        {metaTitle}
                      </Text>
                      <Text as="p" variant="cardText">
                        {metaDescription.metaDescription}
                      </Text>
                    </Flex>
                  </Card>
                </Link>
              </Box>
            );
          }
        )}
      </Flex>
    </MainContainer>
  );
};

export default Cards;
