/** @jsx jsx */
import { jsx } from 'theme-ui';
import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../ui-components/layout';
import MainContainer from '../ui-components/container/container';
import { Image } from '../ui-components/images/images';

// import AlternatingImage from '../components/alternating-image/alternating-image';
import FeaturedArticles from '../components/featured-articles/featured-articles';
import FullScreenImage from '../components/full-screen-image/full-screen-image';
// import PhoneStripe from '../components/phone-stripe/phone-stripe';
import SEO from '../components/seo/seo';
// import SideBySideParagraph from '../components/side-by-side/side-by-side';
// import Text from '../components/text/text';

import { Box, Flex, Card, Link, Text } from 'rebass';

interface BlogPageProps {
  data: {
    site: {
      siteMetadata: {
        name: string;
        tagline: string;
        githubLink: string;
      };
    };
    allContentfulBlogHub: {
      edges: {
        node: {
          id: number;
          metaTitle: string;
          metaDescription: {
            metaDescription: string;
          };
          socialMediaImage: {
            file: {
              url: string;
            };
          };
          language: [];
          sections: [];
        };
      };
    };
    articles: {
      edges: [
        {
          node: {
            slug: string;
            metaTitle: string;
            metaDescription: {
              metaDescription: string;
            };
            image: {
              file: {
                url: string;
              };
            };
          };
        }
      ];
    };
  };
}

export const blogPageQuery = graphql`
  query BlogPageQuery {
    allContentfulBlogHub(limit: 1) {
      edges {
        node {
          id
          metaTitle
          metaDescription {
            metaDescription
          }
          socialMediaImage {
            file {
              url
            }
          }
          language
          sections {
            ...FullScreenImageSection
            ...FeaturedArticles
          }
        }
      }
    }
    articles: allContentfulBlogArticle(
      sort: { order: DESC, fields: sortByDate }
    ) {
      edges {
        node {
          slug
          sortByDate
          metaDescription {
            metaDescription
          }
          metaTitle
          image: socialMediaImage {
            ...ContentfulAsset
          }
        }
      }
    }
  }
`;

// ...SideBySideParagraphSection
// ...TextSection
// ...AlternatingImageSection
// ...PhoneStripeSection

export default class BlogHub extends React.Component<BlogPageProps, {}> {
  public render() {
    const {
      // @ts-ignore
      node: {
        sections,
        metaTitle,
        metaDescription,
        socialMediaImage,
        slug,
        noIndexnoFollow,
        language
      }
      // @ts-ignore
    } = this.props.data.allContentfulBlogHub.edges[0] as BlogPageProps;

    console.log(this.props.data.articles);

    const { edges } = this.props.data.articles;

    console.log(edges);

    const components = {
      ContentfulFullScreenImageSection: FullScreenImage,
      // ContentfulPhoneStripeSection: PhoneStripe,
      ContentfulFeaturedArticles: FeaturedArticles
      // ContentfulAlternatingImageSection: AlternatingImage,
      // ContentfulSideBySideParagraphSection: SideBySideParagraph,
      // ContentfulTextSection: Text
    };

    // @ts-ignore
    const ComponentMatch = data => {
      // @ts-ignore
      const MatchingComponent = components[data.internal.type];
      return <MatchingComponent data={data} />;
    };

    return (
      <Layout>
        <SEO
          title={metaTitle}
          description={metaDescription.metaDescription}
          image={socialMediaImage.file.url}
          pathname={slug}
          lang={language}
          noIndexNoFollow={noIndexnoFollow}
        />
        <main>
          {sections.map((component: any) => (
            <ComponentMatch
              {...component}
              key={component.heading || component.title}
            />
          ))}
          <MainContainer>
            <Text as="h2">Articles</Text>
          </MainContainer>
          <MainContainer my={4} width="100%">
            <Box sx={{ display: 'grid', gridTemplateColumns: ['1', '1', '1', 'repeat(3, 1fr)'], gridGap: '24px', gridAutoFlow: 'row' }}>
              {edges.map(
                ({ node: { slug, metaTitle, metaDescription, image } }): any => {
                  return (
                    <Box key={metaTitle}>
                      <Link href={slug} variant="cardBlogLink">
                        <Card variant="cardImage">
                          <Image src={image} className="image--blog-post" />
                          <Flex flexWrap="wrap" p={[2]}>
                            <Text as="h3" variant="cardTitle">
                              {metaTitle}
                            </Text>
                            <Text as="p" variant="cardText">
                              {metaDescription.metaDescription}
                            </Text>
                          </Flex>
                        </Card>
                      </Link>
                    </Box>
                  );
                }
              )}
            </Box>
          </MainContainer>
        </main>
      </Layout>
    );
  }
}
